import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d5b7fab8 = () => interopDefault(import('..\\pages\\access-your-account.vue' /* webpackChunkName: "pages_access-your-account" */))
const _31caf5a9 = () => interopDefault(import('..\\pages\\access-your-card.vue' /* webpackChunkName: "pages_access-your-card" */))
const _cc93ced0 = () => interopDefault(import('..\\pages\\card-print.vue' /* webpackChunkName: "pages_card-print" */))
const _425c88be = () => interopDefault(import('..\\pages\\program-requirements.vue' /* webpackChunkName: "pages_program-requirements" */))
const _3c1de5f6 = () => interopDefault(import('..\\pages\\rebate-form.vue' /* webpackChunkName: "pages_rebate-form" */))
const _5444002c = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _2f1880f1 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _2f269872 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _2f42c774 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _2f50def5 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _63febc32 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _1e7f281a = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-your-account",
    component: _d5b7fab8,
    name: "access-your-account"
  }, {
    path: "/access-your-card",
    component: _31caf5a9,
    name: "access-your-card"
  }, {
    path: "/card-print",
    component: _cc93ced0,
    name: "card-print"
  }, {
    path: "/program-requirements",
    component: _425c88be,
    name: "program-requirements"
  }, {
    path: "/rebate-form",
    component: _3c1de5f6,
    name: "rebate-form"
  }, {
    path: "/unlock",
    component: _5444002c,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _2f1880f1,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _2f269872,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _2f42c774,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _2f50def5,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _63febc32,
    name: "errors-500"
  }, {
    path: "/",
    component: _1e7f281a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
